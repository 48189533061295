// import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getExpressionTypes } from '../utils/FeatureUtils';
import { FormHelperText } from "@mui/material";
import GlobalContext from "../context/optionContext";
import { sendNewEntityRequest } from "../utils/FetchUtils";

const expressionTypes = getExpressionTypes();
const newPlasmidForm = {
    name: '',
    parent: '',
    expressionType: 'Yeast Expression',
    rationale: '',
    submitterEmail: '',
    sequence: '',
    supplier: '',
}
const newFeatureForm = {
    name: '',
    type: '',
    rationale: '',
    submitterEmail: '',
    sequence: '',
}

const newREForm = {
    name: '',
    cutSequence: '',
    submitterEmail: '',
    rationale: '',
}

const RequestPage = () => {
    const { theme, language } = React.useContext(GlobalContext);

    // const [formData, setFormData] = useState({ newPlasmidForm, newFeatureForm, newREForm });
    const [plasmidData, setPlasmidData] = useState(newPlasmidForm);
    const [featureData, setFeatureData] = useState(newFeatureForm);
    const [enzymeData, setEnzymeData] = useState(newREForm);
    const [requestType, setRequestType] = useState('');
    const [hostOrganism, setHostOrganism] = useState('');
    const handleDataChange = (setter, field) => (event) => {
        setter((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    useEffect(() => {
        console.log('plasmidData', plasmidData);
        console.log('featureData', featureData);
        console.log('enzymeData', enzymeData);
    }, [plasmidData, featureData, enzymeData]);


    const handleSubmit = async (event, plasmidData, featureData, enzymeData) => {
        event.preventDefault();
        // Handle form submission logic here

        // Collect form data
        const formData = {
            requestType,
            plasmidData,
            featureData,
            enzymeData
        };

        // Send the data to the server or API
        // You can use fetch or any other library to make the request
        console.log('Submitting form data:', formData);
        let res = await sendNewEntityRequest(formData).then((res) => {
            // let resJson = res.json();
            console.log('resJson', res);
        })

    };

    return (
        <Layout>
            <Seo title="Request" />
            <h1>Request</h1>
            <div>
                COMING SOON...
                {(false) && <Box component="form" onSubmit={(event) => handleSubmit(event, plasmidData, featureData, enzymeData)} autoComplete sx={{ width: '100%', maxWidth: 800, m: 'auto' }}>
                    <Typography variant="h5" gutterBottom>
                        Request Form
                    </Typography>
                    <hr></hr>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="request-type-label">Request Type</InputLabel>
                        <Select
                            labelId="request-type-label"
                            value={requestType}
                            label="Request Type"
                            onChange={(event) => setRequestType(event.target.value)}
                        >
                            <MenuItem value="addPlasmid">Add New Plasmid</MenuItem>
                            <MenuItem value="addFeature">Add New Feature</MenuItem>
                            <MenuItem value="addRestrictionEnzyme">Add New Restriction Enzyme</MenuItem>
                        </Select>
                    </FormControl>
                    {requestType === "addPlasmid" &&
                        <div>
                            <TextField fullWidth required
                                value={plasmidData.name}
                                onChange={handleDataChange(setPlasmidData, 'name')}
                                helperText="Please enter a name for the plasmid. Ex. pG14-MS2-GFP."
                                label="Plasmid Name"
                                sx={{ mb: 2 }} />
                            <TextField fullWidth required
                                value={plasmidData.parent}
                                onChange={handleDataChange(setPlasmidData, 'parent')}
                                helperText="Please enter the plasmid parent or plasmid type. Ex. pG14."
                                label="Plasmid Parent or Plasmid Type"
                                sx={{ mb: 2 }} />
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="host-organism-label">Host Organism</InputLabel>
                                <Select
                                    required
                                    labelId="host-organism-label"
                                    value={plasmidData.hostOrganism}
                                    label="Expression System"
                                    onChange={handleDataChange(setPlasmidData, 'expressionType')}
                                >
                                    {expressionTypes.map((v, i) => {
                                        return <MenuItem key={i} value={v}>{v}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <TextField fullWidth required
                                value={plasmidData.sequence}
                                onChange={handleDataChange(setPlasmidData, 'sequence')}
                                helperText="Please enter the entire nucleotide sequence without any cloned target genes."
                                label="Sequence" multiline
                                rows={5} sx={{ mb: 2 }}
                                InputProps={{
                                    style: {
                                        fontFamily: "Consolas, 'Courier New', Courier, monospace",
                                        color: 'black',
                                        fontSize: "0.9rem",
                                        // backgroundColor: theme['--background'],
                                        backgroundColor: 'white'
                                    }
                                }}
                            />
                            <TextField value={plasmidData.supplier} onChange={handleDataChange(setPlasmidData, 'supplier')} fullWidth label="Supplier" sx={{ mb: 2 }} />
                            <TextField required fullWidth
                                value={plasmidData.rationale}
                                onChange={handleDataChange(setPlasmidData, 'rationale')}
                                helperText="Please provide as much detail as possible."
                                label="Rationale"
                                multiline
                                rows={4}
                                sx={{ mb: 2 }} />
                            <TextField required fullWidth
                                value={plasmidData.submitterEmail}
                                onChange={handleDataChange(setPlasmidData, 'submitterEmail')}
                                helperText="May be used for direct correspondence."
                                label="Submitter's Email"
                                type="email"
                                sx={{ mb: 2 }} />
                        </div>}
                    {requestType === "addFeature" &&
                        <div>
                            <TextField fullWidth required
                                value={featureData.name}
                                onChange={handleDataChange(setFeatureData, 'name')}
                                helperText="Ex. f1_ori"
                                label="Feature Name"
                                sx={{ mb: 2 }} />
                            <TextField fullWidth required
                                value={featureData.type}
                                onChange={handleDataChange(setFeatureData, 'type')}
                                helperText="Ex. Replication Origin"
                                label="Type"
                                sx={{ mb: 2 }} />
                            {/* <TextField fullWidth required
                                label=""
                                sx={{ mb: 2 }} />
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="host-organism-label">Host Organism</InputLabel>
                                <Select required
                                    labelId="host-organism-label"
                                    value={hostOrganism}
                                    label="Host Organism"
                                    onChange={(event) => setHostOrganism(event.target.value)}
                                >
                                    {/* Add MenuItem components for each host organism here */}
                            {/* {expressionTypes.map((v, i) => {
                                        return <MenuItem key={i} value={v}>{v}</MenuItem>
                                    })}
                                </Select>
                            </FormControl> */}
                            <TextField fullWidth required
                                value={featureData.sequence}
                                onChange={handleDataChange(setFeatureData, 'sequence')}
                                label="Sequence" multiline
                                helperText="Please enter the nucleotide sequence of the feature only."
                                rows={5} sx={{ mb: 2 }}
                                InputProps={{
                                    style: {
                                        fontFamily: "Consolas, 'Courier New', Courier, monospace",
                                        color: 'black',
                                        fontSize: "0.9rem",
                                        // backgroundColor: theme['--background'],
                                        backgroundColor: 'white'
                                    }
                                }}
                            />
                            <TextField fullWidth required
                                value={featureData.rationale}
                                onChange={handleDataChange(setFeatureData, 'rationale')}
                                helperText="Please provide as much detail as possible."
                                label="Rationale" multiline
                                rows={4} sx={{ mb: 2 }} />
                            <TextField fullWidth required
                                value={featureData.submitterEmail}
                                onChange={handleDataChange(setFeatureData, 'submitterEmail')}
                                helperText="May be used for direct correspondence."
                                label="Submitter's Email"
                                type="email"
                                sx={{ mb: 2 }} />
                        </div>}
                    {requestType === "addRestrictionEnzyme" &&
                        <div>
                            <TextField fullWidth required
                                value={enzymeData.name}
                                onChange={handleDataChange(setEnzymeData, 'name')}
                                helperText="Ex. EcoRI, BamHI"
                                label="Name"
                                sx={{ mb: 2 }} />
                            <TextField fullWidth required
                                value={enzymeData.cutSequence}
                                onChange={handleDataChange(setEnzymeData, 'cutSequence')}
                                helperText="Use the symbol '^' to denote the position of the cleavage site. Special sequence characters supported. Ex. G^AATTNNCC"
                                label="Cute Site Sequence (5'-3')" sx={{ mb: 2 }} />
                            <TextField fullWidth required
                                value={enzymeData.rationale}
                                onChange={handleDataChange(setEnzymeData, 'rationale')}
                                helperText="Please provide as much detail as possible."
                                label="Rationale"
                                multiline
                                rows={4}
                                sx={{ mb: 2 }} />
                            <TextField fullWidth required
                                value={enzymeData.submitterEmail}
                                onChange={handleDataChange(setEnzymeData, 'submitterEmail')}
                                helperText="May be used for direct correspondence."
                                label="Submitter's Email"
                                type="email"
                                sx={{ mb: 2 }} />
                        </div>}
                    <Button
                        disabled={!requestType}
                        onClick={() => {

                        }}
                        type="submit" variant="contained" sx={{ backgroundColor: 'purple', color: 'white' }}
                    >Submit
                    </Button>
                </Box>}
            </div>

        </Layout >
    )
}

export default RequestPage
